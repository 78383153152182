import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Link from "next/link";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "32px",
    padding: theme.spacing(4, 3),
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: "#fff",
    boxShadow: "0px 0px 6px #00000029",
    marginBottom: "48px",
    flexWrap: "wrap"
  },
  card: {
    display: "flex",
    position: "relative",
    background: "#fff",
    borderRadius: theme.shape.borderRadius * 2,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "15px",
    padding: theme.spacing(1.5, 0),
    "&::before": {
      content: "''",
      position: "absolute",
      top: "16px",
      left: "-9px",
      width: "14px",
      height: "14px",
      borderRadius: "50%",
      background: theme.palette.primary.main
    }
  },
  image: {
    width: "50px"
  },
  title: {
    font: "normal normal 600 16px/30px Poppins",
    color: "#2E323C"
  },
  text: {
    color: "#2E323C",
    font: "normal normal normal 12px/21px Poppins"
  },
  dots: {
    position: "absolute",
    left: "-24px",
    bottom: "-32px",
    zIndex: 0
  },
  button: {
    background: "#fff",
    position: "relative",
    zIndex: 1,
    borderRadius: theme.shape.borderRadius,
    fontSize: "20px",
    fontWeight: 600,
    color: theme.palette.text.primary,
    textDecoration: "none",
    padding: theme.spacing(1.5, 4),
    boxShadow: "0px 3px 6px #00000029",
    transition: "all 0.3s ease",
    "&:hover": {
      background: theme.palette.text.primary,
      color: "#fff"
    }
  },
  [theme.breakpoints.up("sm")]: {
    card: {
      width: "280px"
    },
    benefits: {
      width: "100%"
    },
    root: {
      justifyContent: "space-between"
    }
  },
  [theme.breakpoints.up("lg")]: {
    root: {
      gap: "unset"
    },
    benefits: {
      width: "unset"
    },
    card: {
      height: "100px",
      justifyContent: "center",
      width: "unset"
    }
  }
}));

const CatalogBannerBenefits = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.benefits}>Benefícios:</h2>
      <div className={classes.card} style={{ width: "230px" }}>
        <img className={classes.image} src="/img/car10.png" alt="" />
        <div>
          <Typography className={classes.title}>Voucher Car10</Typography>
          <Typography className={classes.text}>
            Compre um veículo e<br /> ganhe um voucher de
            <br /> R$ 200,00!!
          </Typography>
        </div>
      </div>
      <div className={classes.card} style={{ width: "230px" }}>
        <img className={classes.image} src="/img/ipva.png" alt="" />
        <div>
          <Typography className={classes.title}>IPVA 2025</Typography>
          <Typography className={classes.text}>
            Comece o ano sem
            <br /> preocupação!
            <br /> O IPVA já vai pago!
          </Typography>
        </div>
      </div>
      <div className={classes.card} style={{ width: "270px" }}>
        <img className={classes.image} src="/img/documents.svg" alt="" />
        <div>
          <Typography className={classes.title}>Documentos?</Typography>
          <Typography className={classes.text}>
            Relaxa! Você não paga pela
            <br /> transferência e emplacamento
            <br /> no padrão Mercosul.
          </Typography>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <img src="/img/dots.svg" alt="" className={classes.dots} />
        <Link href="/conteudo/saiba-mais">
          <a className={classes.button}>Saiba mais</a>
        </Link>
      </div>
    </div>
  );
};

export default CatalogBannerBenefits;
